

.roomsheader{
    width: 100%;
    
}


.Label{
    width: 470px;
    height: 505px;
    background-color: black;
    float: left;
    margin-left: 1px;
    
     
       
}


.bordered{
    margin-left: 30px;
    margin-top: 20px;
    color: gold;
    font-family: Georgia, 'Times New Roman', Times, serif;
    
}
.reduce{
    width:77%;
}

.formdesign{
    color: silver;
    margin-left: 30px;
    width: 80%;

}
.formdesign2{
    
    margin-left: 5px;
    width: 240px!important;

}

.banner{
    width: 790px !important;
    height: 505px !important;
    float: left!important;
    margin-left: 1px!important;
    
    
}
.butt{
    background-color:rgb(36, 33, 33);
}

@media only screen and (max-width: 768px){
    .Label{
        width: 100%;
        height: fit-content;
    }
    .reduce{
        width:56%;
    }
    .formdesign{
        color: silver;
        margin-left: 30px;
        width: 80%;
        margin-bottom: 20px;
        
        
    
    }
}

@media only screen and (max-width: 768px){
    .banner{
        width: 100% !important;
        height: 280px !important;
    }
}


.features{
    font-size: 30px;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
.roomfeatures{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size:large;
    

}

.icard{
    width: 130px!important;
    height: 130px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.jcard{
    width: 130px!important;
    height: 130px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.kcard{
    width: 126px!important;
    height: 120px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.lcard{
    width: 140px!important;
    height: 126px!important;
    border-radius: 100%;
    margin-left: 10px;
}

.mcard{
    width: 134px!important;
    height: 114px!important;
    border-radius: 100%;
    margin-left: 7px;
}


.rcard{
    width: 150px !important;
    height: 150px !important;
    border-radius: 100%;
    margin-left: 10px;
    
}

